/* Resume */

#resume {

	.link-to {
		position: relative;
		top: -4.5em;
	}

	.link-container h4 {
		text-decoration:none;
		border-bottom: none;
		display: inline-flex;

		a {
				padding: 0 .5em;
				margin: 0;
		}
	}

	.education {

		.title {
			text-align: center;
			margin-bottom: 2em;
		}
	}
	.experience {
		.title {
			text-align: center;
		}
		.job {
			margin: 1em;
			p{
				margin: 0;
			}
		}
	}

	.jobs-container {
		.points {
			li {
				margin: 0;
				padding: 0;
                font-size: .9em;
			}
        }
    }
    
    .jobs-container a{
        border-bottom: dotted 1px rgba(160, 160, 160, 0.65);
    }

	.courses {
		padding-top: 1.6em;
		.title {
			text-align: center;
		}
		.course-list {
			h4 {
				white-space: nowrap;
			}
		}
	}

	.skills {
		margin-top: 2em;
		.title {
			text-align: center;
			p {
				font-size: 0.9em;
			}
		}
	}
	.references {
		margin: 2em 0;
		.title {
			text-align: center;
		}
	}
}

.daterange {
	margin-bottom: .2em;
}

.course-container {
    margin-top: 10px;
    font-size: .9em;
}

.degree-container {
	h4 {
		font-weight: normal;
	}

	.degree {
		margin-bottom: .1em;
		text-transform: none;
		letter-spacing: 0.16em;
		font-size: .8em;
	}

	.school {
		text-transform: none;
	}
}

.courses .course-school {
    font-family: "Raleway", Helvetica, sans-serif;
    font-weight: 600;
    margin-top: 0.3em;
}

.courses .course-date {
    margin-top: -5px;
    font-family: "Raleway", Helvetica, sans-serif;
    font-size: 0.8em;
    font-weight: 400;
    letter-spacing: 0.25em;
    line-height: 2.5;
    text-transform: uppercase;
}

.courses .course-name {
    margin-top: 20px;
    font-family: "Raleway", Helvetica, sans-serif;
    font-size: 0.8em;
    font-weight: 400;
    letter-spacing: 0.25em;
    line-height: 2.5;
    text-transform: uppercase;
}

.courses ul li{
    list-style-type: none;
}

.course-info {
    display: inline;
}

@include breakpoint(small) {
	
	.course-container a{
		display: block;
    }
    
}
