
.json-resume-tool {
  margin-top: 20px;
}

.json-resume-tool > div {
  border: none !important;
  font-weight: 100 !important;
  background-color: transparent !important;
}

.json-resume-tool button:not(.circular),
.json-resume-tool a {
  border-radius: 0 !important;
  border-top: 0.25px solid rgba(0,0,0,0.07) !important;
  box-shadow: 0 3px 3px rgba(0,0,0,0.16), 0 3px 3px rgba(0,0,0,0.23) !important;
  font-weight: 100 !important;
  
}

.json-resume-tool button label {
    /* font-size: 10px !important;
    font-weight: 400 !important; */
}

.json-resume-tool button:hover,
.json-resume-tool a:hover {
  background-color: rgba(211, 211, 211, 0.493) !important;
}

.json-resume-tool button:active {
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important; */
}

#json-resume-editor {
  /* font-family: monospace;
  font-size: 12px;
  width: 100% !important;
  height: 100% !important;
  border: 1px solid black; */
}

#root > div > div.ui.scale.down.right.very.wide.visible.sidebar > div.ui.massive.borderless.top.attached.menu > div:nth-child(1) {
  margin-right: 0 !important;
}
/* 
#root > div > div.ui.massive.top.attached.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 52px;
  background-color: transparent;
  border-bottom: none;
}

#root > div > div.ui.massive.top.attached.menu > a,
#root > div > div.ui.massive.top.attached.menu {
  border: none !important;
}

#root > div > div.ui.massive.top.attached.menu > a {
  font-size: 24px !important;
}

#root > div > div.ui.massive.top.attached.menu > a:before {
  display: none;
}

#root > div > div.ui.scale.down.right.very.wide.visible.sidebar > div.ui.massive.borderless.top.attached.menu > div:nth-child(2) {
  font-family: monospace;
  font-style: italic;
  font-size: 14px;
} */
/* 
.options-selector {
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  border: none;
  display: inline-block;
  border-radius: 0;
  -moz-border-radiu: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  height: 100%;
  text-align: center !important;
  padding: .78571429em 1.5em .78571429em;
  background: url(https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_keyboard_arrow_down_48px-128.png) no-repeat right center;
  background-size: 40px 40px;
  cursor: pointer;
}

.json-resume-tool.font-selector button {
  padding: 0;
} */
/* 
#\#resume-order-changer {
  list-style: none;
  appearance: none;
  width: 100%;
  padding: 0;
  margin-top: 0;
} */

.resume-toast-body {
    margin-top: 40px;
  padding-left: 10px;
  color: #263238;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.ui.attached.menu {
  margin: 0 !important;
}

@media only screen and (max-width: 500px) {
  #root > div > aside > div {
    width: 100vw;
  }

  #root > div > div.ui.scale.down.right.very.wide.visible.sidebar > div:nth-child(2) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media only screen and (max-width: 767px) {
  .ui.modal>.header {
    padding: 0 !important;
    padding-right: 0 !important;
    margin: 0;
  }
}

@media print {
  .Toastify{
    display: none;
  }
  body {
    background-color: #fff !important;
  }
}

.errorPanel {
    margin-left: 20px;
    margin-right: 50px;
    margin-bottom: 20px;
    padding: 25px;
    padding-top: 10px;
    border-color: '#a13b3d';
    border: #a13b3d solid 1px;
    min-height: 200px;
    border-radius: 5px;
    background-color: #fee0e0;
}

.errorTitle {
    margin-bottom: 0px;
    color: #b83a3d;
    font-size: 2em;
    font-weight: bold;
}

.errorDivider {
    background-color: #b83a3d;
    height: 1px;
    opacity: 0.7;
}

.errorText {
    color: #b83a3d;
    font-size: 1.2em;
}